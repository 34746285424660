/* App.css */
body {
   font-family: Arial, sans-serif; 
    margin: 0;
    padding: 0;
    line-height: 1.6; 
    background-color: w; 
  }
  


/* App.css */

.header {
  display: flex;
  justify-content: start;  /* 왼쪽과 오른쪽을 나누어 배치 */
  align-items: center;            /* 세로로 가운데 정렬 */
     
  margin-top: 12px;
  margin-left: 20px;
}



 
.headerContainer{
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  border-bottom: solid 1px silver;
}

.logo {
  font-size: 1.5rem;
}

/* 왼쪽 네비게이션 (Home, New Post) */
nav.nav-left {
  display: flex;
  align-items: start;  
  padding-left: 20px; 
}

nav.nav-left ul {
  list-style: none;
  display: flex; 
  gap: 20px;     
}

nav.nav-left li {
  display: inline-block;
  padding-left: 20;
}

nav a { 
  text-decoration: none;
}

nav a:hover {
  color: #007bff;
}

/* 오른쪽 아이템들 (검색, 장바구니, 로그인, 회원가입) */
.header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.header-right .search input {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.header-right .cart,
.header-right .auth a {
  margin-right: 10px;
  margin-top: 3px;
  text-decoration: none;
}

.header-right .cart:hover,
.header-right .auth a:hover {
  color: #007bff;
}

/* 로그인과 회원가입 링크들 스타일링 */
.header-right .auth {
  display: flex;
  gap: 15px; /* 로그인과 회원가입 링크 간 간격 */
}




/* 미디어 쿼리: 화면 너비가 500px 이하일 때 */
@media (max-width: 500px) {
  /* 검색 박스 숨기기 */
  .search input {
    display: none;
  }

  /* 검색 버튼만 보이기 */
  .search-btn {
    display: inline-block;
  }
 
}
 
/* 기본 스타일 */
.home-page {
  padding-top: 20px;
  margin: 0 auto;
  min-width: 1000px; 
}

/* 화면 크기가 768px 이하일 때 (모바일) */
@media (max-width: 768px) {
  .home-page {
    max-width: 375px;  /* 중간 크기 스마트폰에 맞는 너비 */
  }
}

/* 화면 크기가 480px 이하일 때 (작은 모바일 화면) */
@media (max-width: 480px) {
  .home-page {
    max-width: 340px;

  min-width: 300px; 
      /* 작은 화면에 맞는 너비 */
  }
}
 
.content p {
  font-size: 16px;
  line-height: 1.5;
  margin: 10px 0;
}

  /* App 전체 스타일 */
.App {
  display: flex;
  flex-direction: column;   /* 자식 요소들 세로로 배치 */
  min-height: 100vh;         /* 화면 크기만큼 높이 설정 */
  background-color: #f0f0f0;
}

.main-container {
  display: flex; 
}


.TextSwiper {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 25px;
  text-align: center;
  font-weight: bold; 
  padding-top:20px;
}

  
  .post-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  
  .post-card {
    width: 30%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .post-card h2 {
    margin-top: 0;
  }
  
  .post-detail {
    padding: 20px;
  }
  /* App.css */

/* 기본 링크 색상 설정 */
a {
  color: #007bff; /* 파란색으로 링크 텍스트 설정 */
  text-decoration: none;  /* 밑줄 제거 */
}

/* 링크 hover 상태 (마우스를 올렸을 때 색상 변경) */
a:hover {
  color: #0056b3;  /* 어두운 파란색 */
}

/* 방문한 링크의 색상 */
a:visited {
  color: #6c757d;  /* 방문한 링크는 회색 */
}

/* 클릭 중인 상태의 링크 색상 */
a:active {
  color: #003366;  /* 클릭 중에는 더 어두운 파란색 */
}

/* Footer 스타일 */
.footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
 
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-links {
  margin-top: 10px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
}

.footer-links a:hover {
  color: #007bff;
}


.swiper-container {
  width: 100%;
  height: 400px;
}

.swiperWrap{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px; 
}

.swiper-container-web {
  width: 450px;
  height: 450px;
  align-items: center;
  justify-content: center;
}
.swiper-slide img { 
  border-radius: 8px;
}



.loginContainer{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}


.imgButList{
  margin-top:20px
}







.loginDesc{
  margin-top:20px;
  height:500px;
 
}


/* 앱 다운로드 버튼 스타일 */
.download-button {
  background-color: #9403fc;  /* 보라색 배경 */
  color: white;  /* 텍스트 색상 */
  font-size: 16px;  /* 글자 크기 */
  padding: 8px 16px; /* 버튼 내 여백 */
  border: none;  /* 버튼 테두리 제거 */
  border-radius: 5px;  /* 둥근 모서리 */
  cursor: pointer;  /* 마우스를 올렸을 때 손 모양 */

  transition: background-color 0.3s ease;  /* 호버 시 배경색 변화 */
}

.download-button:hover {
  background-color: #9403fc;  /* 호버 시 배경색 */
}

.download-button:active {
  background-color: #9403fc;  /* 버튼 클릭 시 색상 유지 */
}


/* 모바일 화면에서 텍스트 크기 조정 (화면 너비가 768px 이하일 때) */
@media (max-width: 768px) {
  .download-button {
    font-size: 14px;  /* 모바일 화면에서 텍스트 크기를 작게 */
    padding:  8px 16px;  /* 모바일에서 버튼 내 여백을 줄여서 크기 조정 */
  }
}

/* 더 작은 화면에서 텍스트 크기 추가적으로 줄이기 (예: 480px 이하) */
@media (max-width: 480px) {
  .download-button {
    font-size: 12px;  /* 더 작은 화면에서 텍스트 크기를 더 줄임 */
    padding: 8px 16px;  /* 더 작은 화면에서 여백을 줄임 */
  }
}



.InstallButton {
  display: flex;
  width:300px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  background-color: #9634f1ef; /* 버튼 배경색 */
  color: white; /* 텍스트 색 */
  border-radius: 8px; /* 버튼 모서리 둥글게 */
  font-size: 22px; /* 텍스트 크기 */
  font-weight: bold;
  border:none;
  cursor: pointer; /* 커서 스타일 */
  transition: background-color 0.3s ease; /* 버튼 배경색 변화 효과 */
}

.InstallButton:hover {
  background-color: #9634f17e; /* 호버 시 버튼 색 */
}

.InstallButton .icon {
  margin-right: 2px; /* 아이콘과 텍스트 간 간격 */
  width: 50px;
  height: 50px;
}

.InstallButton .icon-ioc {
  margin-right: 2px; /* 아이콘과 텍스트 간 간격 */
  width: 30px;
  height: 30px;
}
.InstallButton span {
  font-size: 20px; /* 텍스트 크기 */
  color: white; 
}

.InstallButtonSpan {
  font-size: 20px; /* 텍스트 크기 */
  color: white;
  padding-top: 10px;
  padding-left: 10px;
}


.InstallWrap {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center; 
}

.nav-left ul li a i {
  margin-left: 8px;    /* 텍스트와 아이콘 사이의 간격 */
  font-size: 14px;     /* 아이콘 크기 */
  color: #8e44ad;      /* 아이콘 색상 */
  border-radius: 50%;   /* 아이콘을 원형으로 만들기 위한 둥근 모서리 */
  padding: 4px;         /* 보더와 아이콘 간의 간격 */
}


.video-list {
  display: flex;
  flex-wrap: wrap;
  width: 850px;
  gap: 15px; /* 아이템 간의 간격을 설정 */
}
/* 더 작은 화면에서 텍스트 크기 추가적으로 줄이기 (예: 480px 이하) */
@media (max-width: 500px) {
  .video-list {
    display: flex;
    width: 100%; /* 예시: 화면 크기에 맞게 너비 조정 */
    flex-wrap: wrap;
    gap: 15px; /* 아이템 간의 간격을 설정 */
  }
  
 }


.product-item { 
  /* 한 행에 2개씩 배치: 50% 너비에서 간격을 뺀 크기 */
 box-sizing: border-box; /* padding과 border가 요소 크기에 포함되도록 설정 */
  width:200px;
}



/* 더 작은 화면에서 텍스트 크기 추가적으로 줄이기 (예: 480px 이하) */
@media (max-width: 500px) {
 .product-item {
   width: calc(50% - 15px);
 
    /* 한 행에 2개씩 배치: 50% 너비에서 간격을 뺀 크기 */
   box-sizing: border-box; /* padding과 border가 요소 크기에 포함되도록 설정 */
 }
 
}

.product-content{
  display: flex;
  flex: 1;
  justify-content: start;
  padding-left: 10px;
}




.video-item-list {
  padding: 0;
  margin-left: 0;
  list-style-type: none; 
}

.imgVideoProd {
  border-radius: 8px;   /* 둥근 모서리 */
  border: 1px solid #ddd; /* 회색 테두리 */
  width: 140px; /* 이미지를 부모 컨테이너에 맞게 조정 */
  height: 140px; /* 비율에 맞게 높이를 자동으로 조정 */
}


.containerVideo{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center; 
  margin-top: 10px;
}


a {
  text-decoration: none;  /* 기본적으로 밑줄 없애기 */
  color: inherit;         /* 부모 요소의 색상 상속 */
  cursor: pointer;       /* 클릭할 수 있는 손 모양 커서 */
}

a:visited {
  color: inherit;         /* 방문한 링크도 색상 변경 없이 그대로 유지 */
}

a:hover {
  text-decoration: none;  /* hover 시에도 밑줄 제거 */
  color: inherit;         /* hover 시 색상 변경 방지 */
}

a:focus {
  outline: none;          /* 클릭 후 테두리 없애기 */
}

.main-image{
  width: 330px; /* Adjust the size of the overlay image */
  height:300px; 
}


.back-button {
   border: none; 
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
 





/* Style for the product list container */
.relative-product-list {
  display: flex;
  flex-wrap: wrap; /* Wrap items to the next line if the container is small */
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

/* Style for each product item */
.relative-product-item {
  display: flex;
  min-width: 310px; /* Fixed width for each product */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 10px;
}

/* Style for the image section */
.relative-product-image {
  flex: 1;
  margin-right: 20px;
}

.relative-product-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Style for the product details (name and price) */
.relative-product-details {
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
}

/* Product name style */
.relative-product-details h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

/* Product price style */
.relative-product-price {
  font-size: 16px;
  color: #e91e63; /* Pink color for price */
  margin-top: auto;
}


.relative-container{
  flex: 1;
  display: flex;
  min-width: 320px;
  flex-direction: column;
  padding-left: 10px;
  justify-content: space-between;
  padding-bottom: 20px;
}


.mypage-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}


.coup-container{
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 10px;
}
 
.coup-container img {
  margin-right: 10px; /* 이미지와 텍스트 사이에 여백 추가 */
 
}

.coup-container span {
  padding-left: 10px; /* '쿠팡 상품' 앞에 패딩 추가 */
}

.home-page{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left:solid 1px silver;
  border-right:solid 1px silver;

  max-width: 900px;
}

.home-page-section{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px; 
  font-size: 19px;
  text-align: center;

}
.event-page-section{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px; 
  font-size: 19px;
  text-align: center;
  padding-bottom: 20px;
 
}
.productWrap{
  padding: 10px; 
  margin-bottom: 20px;
  border-bottom: solid 1px silver;
}


/* 기본 상태로 iframe을 숨깁니다 */
.WrapProduct iframe {
  display: none;
}

/* iframe을 표시하려면 'show' 클래스를 추가합니다 */
.WrapProduct iframe.show {
  display: block;
}


.label-box{
  min-width: 340px; 
  transform: scale(0.9); /* 80% 비율로 축소 */
 
}


 

.step1 {
  font-family: Arial, sans-serif; 
  background-color: #E0F7FA; 
  padding: 20px;
  border: 1px solid #B2EBF2;
}
.step2 {
  font-size: 24px; font-weight: bold;


}
.step3 {
  font-size: 16px; font-weight: bold;


}
.step4 {
  font-size: 20px; font-weight: bold;
  text-align: right;
}
.step5 {
  font-size: 20px; font-weight: bold;

}
.step6 {
  font-size: 20px;  
  font-weight: bold;

}

.barcode {
  display: flex;
  justify-content: center;
  align-items: flex-start;  
}
 
.wrapBar{
  display: flex; 
  
  justify-content: space-between;
  flex-direction: row; 
  
}
.wrapBarSale{
  border-top: solid 1px silver; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row; 
  
}


.loginWrap{
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column; 
  text-align: center;
  font-size: 25pt;
}


.titleLabel{
  padding: 10px;
  font-size: 18pt;
  font-weight: bold;
}

.home-image {
  width: 1200px;
  height: 340px;
}

/* Media query for screens 400px or smaller */
@media (max-width: 390px) {
  .home-image {
    content: url('/public/img/sale_380.png'); /* smaller image */
    width: 100%;
    height: auto;
  }
}

.mySwiper{
  height: 300px;
  display: flex;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px; 

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}




/* 더 작은 화면에서 텍스트 크기 추가적으로 줄이기 (예: 480px 이하) */
@media (max-width: 400px) {
    
  .productName{
    width: 170px;
    overflow: hidden;
    white-space: nowrap;     /* Prevent the text from wrapping to the next line */
    text-overflow: ellipsis;
    font-size: 12pt;
  }

 
}
.productName{  
  overflow: hidden;
  white-space: nowrap;     /* Prevent the text from wrapping to the next line */
  text-overflow: ellipsis
}


.search-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 20px;
}

.search-box{
  width: 500px;
  height: 50px;
  border: solid 10px rgb(199, 106, 225);
  border-radius: 200px;

}



/* 더 작은 화면에서 텍스트 크기 추가적으로 줄이기 (예: 480px 이하) */
@media (max-width: 400px) {
  .search-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 20px;
  }
  
  
  .search-box{ 
    magrin:20px;
    width: 80%;
    height: 50px;
    border: solid 10px rgb(199, 106, 225);
    
  } 
 
}



.img-desc{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1; 
}


.imgExcluded {
  width: 400px;
  height: 350px;
}


/* 더 작은 화면에서 텍스트 크기 추가적으로 줄이기 (예: 480px 이하) */
@media (max-width: 400px) {
  
.imgExcluded {
  width: 380px;
  height: 350px;
}

 
}

