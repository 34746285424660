html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 13pt;
}
a{
  text-decoration: none;
  color: black;

}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

li {
  list-style-type: none;
  align-items: center;
  justify-content: start;

  margin-bottom: 5px;
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
  flex-direction: column;
}

.top {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.topMargin {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: solid 1px silver;
}
.ProductPart {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.InstallWrap {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.descImageWrap{
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;  
}
.descImage{
  width: 300px;
  height: 500px;
  
}
.swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* 슬라이드 이미지 컨테이너 */
.slide-container {
  position: relative;  /* 버튼을 이미지 위에 배치하기 위해 설정 */
}
/* 
.swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 8px;
} */

.img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}



.topHeader  {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px; 
  border-bottom:solid 1px silver ;
}

.topHeaderMobile {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px; 
  border-bottom:solid 1px silver ;
}

/* '다음' 버튼 (아이콘) */
.swiper-button-next {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10; /* 버튼이 이미지 위에 오도록 설정 */
}

/* '이전' 버튼 (아이콘) */
.swiper-button-prev {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10; /* 버튼이 이미지 위에 오도록 설정 */
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.swiper-button-next i, .swiper-button-prev i {
  font-size: 24px; /* 아이콘 크기 조정 */
}



.TextSwiper {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  padding-left: 40px;
  
  padding-right: 50px;
  padding-bottom: 20px;
}


.InstalDivider { 
  height: 20px;
} 
.InstallButton {
  display: flex;
  width:300px;
  height: 50px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  background-color: #9634f1ef; /* 버튼 배경색 */
  color: white; /* 텍스트 색 */
  border-radius: 8px; /* 버튼 모서리 둥글게 */
  font-size: 22px; /* 텍스트 크기 */
  font-weight: bold;
  border:none;
  cursor: pointer; /* 커서 스타일 */
  transition: background-color 0.3s ease; /* 버튼 배경색 변화 효과 */
}

.InstallButton:hover {
  background-color: #9634f17e; /* 호버 시 버튼 색 */
}

.InstallButton .icon {
  margin-right: 2px; /* 아이콘과 텍스트 간 간격 */
  width: 50px;
  height: 50px;
}

.InstallButton .icon-ioc {
  margin-right: 2px; /* 아이콘과 텍스트 간 간격 */
  width: 30px;
  height: 30px;
}
.InstallButton span {
  font-size: 20px; /* 텍스트 크기 */
  color: white; 
}

.InstallButtonSpan {
  font-size: 20px; /* 텍스트 크기 */
  color: white;
  padding-top: 10px;
  padding-left: 10px;
}


.swiper-container {
  width: 100%;
  height: 400px;
}

.swiperWrap{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px; 
}
.imgExcluded {
  width: 450px;
  height: 350px;
}
.swiper-container-web {
  width: 450px;
  height: 450px;
  align-items: center;
  justify-content: center;
}
.swiper-slide img { 
  border-radius: 8px;
}

.topProducts {
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 13pt;
  flex-wrap: wrap;
}

.footer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: solid 1px silver;
  height: 100px;
  background-color: black;
  color: white;
  /* position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center; */
}

.desc1 {
  line-height: 30px;
  margin: 20px;
}

.martContainer {
  margin-top: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
}

.topMargin {
  padding: 30px;
}

.wrapItem {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.setWrap {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;

  align-items: center;
}
.setWrapItem {
  display: flex;
  flex: 1;
  flex-direction: row;
  min-width: 400px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.WrapUl {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  min-width: 350px;
}
.imageThum {
  border-radius: 3px;
}
.listUl {
  flex: 1;
  flex-direction: row;
  padding: 10px;
  flex-wrap: wrap;
  margin-left: 10px;
}
.listUl img {
  border-radius: 15px;
}

.recentListUl {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 7px;
  flex-wrap: wrap;
}

.productListLi {
  display: flex;
  flex: 1;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: black;
  border-bottom: solid 1px silver;
}

.buttonToDetail {
  border: solid 2px dodgerblue;
  color: dodgerblue;
  background-color: white;
  text-align: center;
  padding: 7px;
  font-size: 9pt;
  border-radius: 3px;
}

.productRecentListLi {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  text-decoration: none;
  color: black;
  border: solid 1px #f3eeed;
  padding-bottom: 10px;
  padding-top: 10px;
}

.desc {
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.descInner {
  white-space: initial;
  width: 200px;
}

.productRecentTitle {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.descLi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11pt;
}

.price {
  color: black;
  font-size: 11pt;
}

.titleSet {
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  justify-content: start;
  flex: 1;
  display: flex;
}

.bottomLine {
  display: flex;
  flex: 1;
  border-bottom: 1px silver;
}

.titleCoupangPartner {
  color: silver;
  font-size: 7pt;
  align-items: flex-end;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-top: 20px;
}

.chartDiv {
  display: inline;
  margin-left: 10px;
}

.buttonToCoupangWrap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-decoration: none;
}
.wrapPrice {
  flex: 1;
  margin-left: 10px;
}
.liDesc1 {
  flex: 1;
  display: flex;
  justify-content: end;
  font-size: 7pt;
}
.liDesc2 {
  flex: 1;
  display: flex;
  justify-content: end;
  color: red;
  font-size: 7pt;
}
.liPrice {
  flex: 1;
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.buttonToCoupangWrapList {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
  text-decoration: none;
}
.priceInfo {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  flex: 1;
}

.buttonToCoupangDetail {
  height: 40px;
  background-color: dodgerblue;
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  color: white;
  font-weight: normal;
  text-align: center;
  border-radius: 3px;
  font-size: 10pt;
  vertical-align: middle;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.buttonToCoupang {
  height: 30px;
  background-color: dodgerblue;
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  color: white;
  font-weight: normal;
  text-align: center;
  border-radius: 3px;
  font-size: 10pt;
  vertical-align: middle;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
}

.buttonToCoupangWrapTag {
  text-decoration: none;
}

.imgSeoul {
  flex: 1;
  margin-left: 20px;
}

.searchArea {
  justify-content: end;
  align-items: end;
  display: flex;
  flex: 1;
  flex-direction: row;
}

.searchButton {
  border: solid 1px silver;
  padding: 6px;
  border-radius: 2px;
  width: 18px;
  cursor: pointer;
}

.searchInput {
  width: 100px;
  height: 28px;
  border: solid 1px silver;
  margin-right: 2px;
}

.detailWrapp {
  flex: 1;
  /*display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.detailImage {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detailWrappTitle {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-weight: bold;
}
.detailContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detailNavigation {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
  justify-content: space-between;
}

.descBox1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 10px;
  line-height: 30px;
}

.vendorTitle {
  font-size: 12px;
  border: solid 1px #e90c20;
  color: white;
  background-color: #e90c20;
  border-radius: 5px;
  padding-left: 5px;
  align-items: center;
  padding-right: 5px;
  flex: 1;
}

.nameTitle {
  font-weight: bold;
  font-size: 19px;
  margin-top: 5px;
}

.descBox2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 10px;
  line-height: 30px;
  margin-top: 20px;
}

.detailButtonWrap {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.shareButton {
  width: 100px;
  flex: 1;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: normal;
  text-align: center;
  border-radius: 5px;
  font-size: 10pt;
  color: #454243;
  height: 40px;
  background-color: #d5cdce;
  text-decoration: none;
}

.shareButton:active {
  box-shadow: 1px 1px 0 rgb(0, 0, 0, 0.5);
  position: relative;
  top: 2px;
}

.shareText {
  text-decoration: none;
}

.coupangButtonContainer {
  flex: 3;
}

.moreWrap {
  flex: 1;
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-size: 11pt;
  color: gray;
  font-weight: normal;
}

.boxHref {
  text-decoration: none;
  color: black;
}

.imageWrapLi {
  flex: 1;
  display: flex;
  justify-content: center;
}



.titleWrap{
  font-size: 16px;
  padding: 10px; 
  font-weight: 900;
  border-bottom: solid 1px silver;
  margin-top: 20px;
  align-items: center;
  justify-content: start;
  flex: 1;
  display: flex;
  text-align: left;
}

.displayContainer{

  width:300px;
}

.displayRoot {
  align-items: center;
  justify-content: center;
  width: 100%;

}

.displayImage{
  width: 100%;
  height: 200px;
}

.itemlistRoot{
  width: 100%;
  height: 300px;
  overflow-x: scroll;
  overflow-y: hidden;
  
}
.imageList {  
  flex-direction: row;
  flex: 1;
  display: flex;
}

.item{
  height: 200px;
  width:  50%;
  padding: 10px;
}


.imgProduct{
  height: 200px;
  width: 200px;
}


.descContainer{
  text-decoration: none;

  padding-left:20px;
  padding-top: 20px;
  color: black;
  font-size: 12pt;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.deliverySection{
  color: cadetblue;
}

.wrapper{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content:space-between;
  margin-top: 10px;
  height: 1000px;
  

}

.bottom-navigator{
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 70px;
  font-size: small;
  border-top: solid 1px silver;

}

.sticky{
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  height: 70px;
  width: 100vw;
  background: white;
}

.invertTitle {
  
  color: #fff;   
  -webkit-text-stroke: 2px #000;
}

 
.title-shadow-pink {
  text-shadow: 0.04em 0.04em 4px #f55,
    -0.04em 0.04em 2px #faa,
    0.04em -0.04em 2px #faa,
    -0.04em -0.04em 2px #faa,
    0.1em 0.1em 5px #fff,
    -0.1em 0.1em 5px #fff,
    0.1em -0.1em 5px #fff,
    -0.1em -0.1em 5px #fff;
}
 
.heartBody{
  background-color: #fff;
}

.heart {
  color: red 
}

.loginContainer{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}


.imgButList{
  margin-top:20px
}



.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}


.overlayContainer .imageOverlay {
  opacity: 0.5;
}



.overlay {
  position: absolute;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 226px;
  width: 220px;
  background-color: rgb(134, 138, 134);
}

.gfg1 {
  margin-top: 80px;
  color: white;
  font-size: 40px;
  font-weight: bold;
}      
.gfg2{
    font-size: 20px;
}